/* GLOBAL */
/* app */
.App {
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: auto;
	margin: 0;
	padding: 0;
}

/* navbar */
.navbar {
	background-color: #0a1a30;
	color: white;
	padding: 0 0 0 20px;
}
.navbar .abbott-logo {
	width: 120px;
	margin: 0;
	padding: 0;
}

/* body contents */
.body-contents {
	padding: 12vh 10vw 0 10vw;
}

/* input field */
.input-box {
	height: 45px;
	border-radius: 8px;
	border-color: #0a1a30;
}

.input-prefix {
	border: 1px solid #0033a1;
	height: 38px;
	width: 38px;
	border-radius: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
}

.custom-number-input {
	border: 1px solid #0033a1;
	border-radius: 6px;
	height: 38px;
	width: calc(100% - 45px);
}

/* form placeholder */
.form-control::placeholder,
.custom-number-input::placeholder {
	text-align: center;
	font-weight: 400;
	font-size: 14px;
}

/* input group */
.input-group .input-group-prepend .input-group-text {
	width: 45px;
	height: 45px;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	background-color: #0a1a30;
	border-color: #0a1a30;
	color: white;
}
.input-group .input-group-prepend .input-group-text i {
	margin: auto;
}

/* big button */
.big-button {
	background-color: #0a1a30 !important;
	color: white;
	height: 50px;
	font-size: 22px;
	font-weight: 900;
	border: 0;
	border-radius: 8px;
}

.big-button-alt {
	background-color: white;
	color: #0a1a30;
	height: 50px;
	font-size: 22px;
	font-weight: 900;
	border: 1px #0a1a30 solid;
	border-radius: 8px;
}

.swal2-radio {
	flex-direction: column;
	margin: 0;
	padding: 0;
	justify-content: space-between;
}

/* checkbox box */
.checkbox-div {
	font-size: 10px;
	margin: 1vh 5vw;
	text-align: justify;
	line-height: 1.1em;
}
.checkbox-div .form-check-label {
	margin-top: 5px;
}

/* otp container */
#otp-container {
	text-align: center;
	width: 295px;
	overflow: hidden;
	box-sizing: border-box;
	margin: auto;
}

/* SALES REP */
#sr-dashboard {
	padding-top: 12vh;
}

.pill-btn {
	height: 25px;
	width: 100px;
	border: 1px white solid;
	padding: 1px 0 0 0;
	margin: 0;
	text-align: center;
	font-weight: bold;
}
#scan {
	/* border-top-left-radius: 20px;
  border-bottom-left-radius : 20px; */
	border-radius: 500px;
}

#manual {
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
}

/* .react-select {
  height : 50px;
  border : 1px red solid;
} */

/* USER */
#user-dashboard {
	background-color: #0a1a30;
	height: 130vh;
	width: 100vw;
	color: white;
	margin: 0;
	padding: 11vh 5vw 0 5vw;
	text-align: center;
}

#user-dashboard.redeemed {
	background-color: white;
	color: #0a1a30;
	padding: 10vh 0 0 0;
	height: 100%;
}

#user-dashboard.redeemed #redeemed {
	width: 200px;
	margin: auto;
	background-color: #0a1a30;
	color: white;
	font-weight: bold;
	font-size: 20px;
	padding: 10px 0;
	border-radius: 10px;
}

#user-dashboard.redeemed #redeemed-info {
	margin-top: 50px;
	font-weight: bold;
	font-size: 18px;
}

#big-amount {
	font-size: 80px;
}

/* .brand-button {
  border : 1px #0a1a30 solid;
  width : 140px;
  height : 140px;
  border-radius : 12px;
}

.brand-button img {
  height : 100px
} */

/* ////////////////////////////////////////////////////// */
#product-container {
	border: 1px lightgrey solid;
	width: 265px;
	height: 310px;
	margin: auto;
	padding: 0;
	border-radius: 10px;
	box-sizing: border-box;
	overflow: hidden;
	box-shadow: 2px 2px 3px lightgrey;
	background-color: white;
}

#flex-product {
	display: flex;
	flex-direction: row;
	height: 100%;
	justify-content: space-around;
}

#flex-product .btn {
	padding: 0 5px;
	margin: 0;
	background-color: white;
}

.products {
	height: 100%;
}

.product {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.products img {
	width: 200px;
}

.products p {
	font-weight: bold;
	font-size: 18px;
	color: black;
}

#circle-dot-container {
	margin-top: 15px;
	display: flex;
	justify-content: center;
}

#circle-dot-container .circle-dot {
	height: 10px;
	width: 10px;
	border: 1px black solid;
	margin: 0 5px;
	border-radius: 50%;
}

/* ////////////////////////////////////////////////////// */

#voucher-page {
	padding-top: 12vh;
	margin: 0;
}

#voucher-page #voucher-container,
#user-dashboard.redeemed #voucher-container {
	padding: 0;
	margin: 25px 0 0 0;
}

#voucher-page #voucher-container #voucher-header,
#user-dashboard.redeemed #voucher-container #voucher-header {
	display: flex;
	flex-direction: row;
}

#voucher-page #voucher-container #voucher-header #voucher-image,
#user-dashboard.redeemed #voucher-container #voucher-header #voucher-image {
	width: 50vw;
}

#voucher-page #voucher-container #voucher-header #voucher-info,
#user-dashboard.redeemed #voucher-container #voucher-header #voucher-info {
	width: 50vw;
	padding: 12px 0 0 0;
	border-top: 4px #0a1a30 solid;
}

#voucher-page #voucher-container #voucher-header #voucher-info h1,
#user-dashboard.redeemed #voucher-container #voucher-header #voucher-info h1 {
	font-weight: bold;
	font-size: 65px;
	margin: 0;
	padding: 0;
}

#voucher-page #voucher-container #voucher-store,
#user-dashboard.redeemed #voucher-container #voucher-store {
	background-color: #0a1a30;
	color: white;
	padding: 5px;
	margin-top: 4px;
}

#voucher-page #voucher-container #voucher-store p,
#user-dashboard.redeemed #voucher-container #voucher-store p {
	font-weight: bold;
	font-size: 26px;
	margin: 0;
	padding: 0;
}

#voucher-page #voucher-container #voucher-footer,
#user-dashboard.redeemed #voucher-container #voucher-footer {
	margin-top: 50px;
}

#voucher-page #voucher-container #voucher-footer #expired-date {
	font-size: 18px;
}

/* #voucher-page #voucher-container {
  background-color: #0a1a30;
  color : white;
  margin-top : 5vh;
  border-radius : 15px;
}

#voucher-page #voucher-div {
  padding : 30px 0 0 0;
  margin : 0;
  position : relative;
  height : 60px;
  width : 100%;
}

#voucher-page #voucher-div #voucher-line {
  border-top : 4px white dashed;
}

#voucher-page #voucher-div .voucher-ball {
  height : 60px;
  width : 60px;
  background-color : white;
  border-radius: 50%;
  position : absolute;
}

#voucher-page #voucher-div #left-ball {
  left : -28px;
  top : 0px;
}

#voucher-page #voucher-div #right-ball {
  right : -28px;
  top : 0px;
}

#voucher-page #voucher-container #voucher-head {
  padding : 50px 4vw 5px 4vw;
}

#voucher-page #voucher-container #voucher-body {
  padding : 5px 4vw 20px 4vw;
}
*/
#voucher-page #voucher-container #voucher-button {
	border: none;
	background-color: #0a1a30;
	height: 50px;
	width: 200px;
	font-weight: bold;
	color: white;
	font-size: 24px;
	border-radius: 10px;
}

#expired-page {
	background-color: #0a1a30;
	color: white;
	height: 100vh;
	border: 1px #0a1a30 solid;
}

#expired-page #expired-box {
	background-color: white;
	color: #0a1a30;
	width: 320px;
	margin: 20vh auto 0 auto;
	padding: 45px 5vw 0 5vw;
	position: relative;
	border-radius: 12px;
}

#expired-page #expired-box #hourglass-container {
	position: absolute;
	background-color: #4dab4a;
	color: white;
	height: 80px;
	width: 80px;
	border: 3px white solid;
	border-radius: 50%;
	top: -40px;
	left: 50%;
	transform: translate(-50%, 0);
}

#expired-page #expired-box #hourglass-container .far {
	font-size: 50px;
	margin-top: 12px;
}

#expired-page #expired-box .btn {
	background-color: #0a1a30;
	color: white;
	font-weight: bold;
	margin: 30px 0 15px 0;
}

#checkout-container {
	border: 1px #bfdcea solid;
	border-radius: 15px;
	box-shadow: 2px 2px 5px lightgrey;
	padding-top: 50px;
	position: relative;
}

#checkout-container #checkout-check {
	font-size: 80px;
	background-color: white;
	border-radius: 50%;
	color: #4dab4a;
	position: absolute;
	top: -40px;
	left: 50%;
	transform: translate(-50%, 0);
}

#checkout-container #voucher-image-container {
	position: relative;
}

#checkout-container #voucher-image-container img {
	z-index: -1;
}

#checkout-container #voucher-image-container #voucher-protector {
	z-index: 1;
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
}

#checkout-container .btn {
	background-color: #0a1a30;
	color: white;
	font-weight: bold;
	margin: 20px 0;
	width: 120px;
}

/* RETAILER */
#retailer-box {
	border: 2px #bfdcea solid;
	border-radius: 10px;
	height: 220px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	font-weight: bold;
	box-shadow: 2px 2px 3px #bfdcea;
	margin-top: 5vh;
}

#retailer-box #retailer-header {
	font-size: 16px;
	margin: 0;
}

#retailer-box #retailer-amount {
	font-size: 66px;
	padding: 0;
	margin: 0;
}

#retailer-box #retailer-brand {
	font-size: 22px;
	margin: 0;
}

/* ADMIN */
#admin-login {
	background-color: #0a1a30;
	height: 100vh;
	width: 100vw;
	padding-top: 35vh;
	text-align: center;
	overflow: hidden;
	box-sizing: border-box;
}

#admin-login #admin-login-container {
	display: flex;
	flex-direction: row;
	text-align: center;
	justify-content: center;
}

#admin-login #admin-login-container #admin-image-container {
	color: white;
	font-weight: bold;
	margin-right: 5vw;
}

#admin-login #admin-login-container #admin-image-container #image-container {
	max-width: 500px;
}

#admin-login #admin-login-container #admin-login-form {
	background-color: white;
	max-width: 400px;
	padding: 30px;
}

#navbar-container {
	display: flex;
}

#react-pro-sidebar {
	height: 100vh;
	background-color: #0a1a30;
	color: white;
}

.admin-page {
	display: flex;
	height: 100vh;
	width: 100vw;
}

.admin-body {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	box-sizing: border-box;
}

.admin-content {
	max-height: 93vh;
	overflow-y: auto;
	overflow-x: hidden;
	box-sizing: border-box;
	padding: 25px;
}

.admin-content .card-header,
.admin-content .card-footer {
	color: white;
	background-color: #0a1a30;
}

/* FAQ PAGES */
#paedsLayout {
	height: 100vh;
	width: 100vw;
	background-image: url(./assets/images/paedsLayout.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-clip: border-box;
	margin: 0;
	padding-left: 5vw;
	padding-right: 5vw;
	overflow: hidden;
	box-sizing: border-box;
}

#adultLayout {
	height: 100vh;
	width: 100vw;
	background-image: url(./assets//images/adultLayout.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-clip: border-box;
	margin: 0;
	padding-left: 5vw;
	padding-right: 5vw;
	padding-top: 5vh;
	overflow: hidden;
	box-sizing: border-box;
}

#retailerLayout {
	height: 100vh;
	width: 100vw;
	background-image: url(./assets//images/retailerLayout.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-clip: border-box;
	margin: 0;
	padding-left: 5vw;
	padding-right: 5vw;
	padding-top: 5vh;
	overflow: hidden;
	box-sizing: border-box;
}

#logo-container {
	padding: 10px 25px 0 25px;
	text-align: center;
	margin-bottom: 50px;
}

#language-container {
	width: 300px;
	margin-left: auto;
	height: 35px;
	overflow: hidden;
	border-radius: 10px;
	box-sizing: border-box;
}

#language-container .language-button {
	height: 100%;
	width: 33.33%;
	border: none;
	background-color: #b7b5b6;
	color: #929292;
	overflow: hidden;
	outline: none;
}

#language-container .language-button.leftButton {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

#language-container .language-button.rightButton {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

#video-container {
	height: 200px;
	margin-top: 20px;
	text-align: center;
}

#video-container iframe {
	width: 100%;
	height: 100%;
}

.newpopup {
	overflow: hidden;
	border-radius: 15px !important;
}

.newpopup .swal2-content {
	color: white !important;
	padding: 25px 20px 0 !important;
	background-color: #0a1a31;
}

.newpopup .swal2-actions {
	background-color: #0a1a31;
	margin: 0 !important;
	padding: 0px 10px 25px;
}

.newpopup .swal2-actions button {
	color: #0a1a31;
	background-color: white;
	width: 100%;
	border-radius: 10px !important;
	font-size: 14px !important;
}

.whatsapp-bg {
  background-color: rgb(56,22,83);
	min-height: 100vh;
	color: white;
}

.whatsapp-topbar {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 8% 5%;
}

.whatsapp-topbar .img {
	width: 28%
}

/* Welcome to Abbott care Pack

width: 287px;
height: 120px;

font-family: 'Brandon Grotesque';
font-style: normal;
font-weight: 500;
font-size: 42px;
line-height: 60px;
text-align: center;

color: #FFFFFF;


Inside auto layout
flex: none;
order: 0;
flex-grow: 0; */
